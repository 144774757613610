.maintenance-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  a:link {
    text-decoration: none;
  }
  .maintenance-bg {
    width: 700px;
  }
  .maintenance-h2 {
    font-size: 2rem;
    font-weight: 300;
  }
  .maintenance-message {
    font-size: 1.4em;
    color: #979797;
    font-weight: 300;
    padding: 0.5rem;
    white-space: pre-line;
  }
  .btn-go-back {
    .flaticon-arrow {
      padding-right: 10px;
    }
    margin-top: 10px;
    margin-right: 0;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
  }
}
