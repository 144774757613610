@import '_containers';
body,
html {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: myriad-pro, sans-serif;
  color: $textstandard;
  scroll-behavior: smooth;
  line-height: 1;

  //Responsive
  @include breakpoint(xs) {
    font-size: 14px;
  }
  @include breakpoint(sm) {
    font-size: 14px;
  }
  @include breakpoint(md) {
    font-size: 15px;
  }
  @include breakpoint(lg) {
    font-size: 16px;
  }
}
