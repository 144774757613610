.modal-container {
  .ant-modal-footer {
    padding-inline: 0;
  }
  .ant-modal-body {
    padding-bottom: 8px;
    padding-inline: 0;
    .ant-carousel {
      width: 80%;
    }
  }
  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    .ant-btn-round {
      padding-inline: 10px;
    }

    .navigation-btn {
      height: auto;
      border-radius: 10px;
      padding-inline: 5px;
    }

    .carousel-container {
      margin-right: 10px;
      margin-left: 10px;
      overflow: auto;
      max-height: 300px;

      .slick-list {
        margin-bottom: 10px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .message-content {
        margin-bottom: 30px;
        text-align: center;
      }

      .message-title {
        @include flexbox(0 0 auto, row, flex-start, center);
        padding: 0.5rem 0;
        font-size: 1.2rem;
        text-align: center;
        justify-content: center;
      }
      .info-icon {
        @include flexbox(0 0 auto, row, flex-start, center);
        color: $syscoblue;
        font-size: 3rem;
        padding: 0;
        justify-content: center;
      }
      .vertical-spacer {
        display: block;
        min-height: 10px;
      }
      .message-do-not-show-section {
        display: flex;
        justify-content: center;

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: $syscored;
          border-color: $syscored;
        }

        .ant-checkbox + span {
          color: gray;
        }
      }
    }
  }

  .ok-btn {
    display: flex;
    justify-content: center;

    .back-to-app-btn {
      background-color: $syscogreen;
      border-color: $syscogreen;
      color: $textalternative;
      &:active {
        background-color: darken($syscogreen, 5%) !important;
      }
      &:hover,
      &:focus {
        background-color: lighten($syscogreen, 15%) !important;
        border-color: $syscogreen;
        color: $textalternative;
      }
      &.invert {
        background-color: $textalternative;
        color: $syscogreen;
        &:hover,
        &:focus,
        &:active {
          color: $textalternative;
        }
      }
    }
  }
  .ant-carousel .slick-dots {
    position: relative;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: unset !important;
  }
  .ant-carousel .slick-dots li button {
    background-color: rgb(93, 139, 226);
  }

  .ant-carousel .slick-dots li.slick-active button {
    background-color: #032f59;
  }
}
