/***********************************************************
    LOGIN PRIMARY SASS FILE - BUILT ON BT-UX-FRAMEWORK
    Author: Michael Lu 
    (Auto Compiled to CSS during runtime)
***********************************************************/

.login-wrapper {
  @include flexbox();
  background: black url('/styles/images/loginbg.jpg') no-repeat center center;
  background-size: cover;
  justify-content: center;
  align-items: center;

  .login-panel {
    @include flexbox(0 0 auto, column, flex-start, center);
    padding: 1rem;
    border-radius: 23px;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.55);
    form {
      @include flexbox(0 0 auto, column, flex-start, center);
    }
    .logo {
      width: 15rem;
      margin: 1rem 0;
    }
    .title {
      font-weight: 300;
      font-size: 1.2rem;
      margin: 1rem 0;
      color: $textstandard;
      opacity: 0.7;
      &.bold {
        font-weight: 600;
      }
    }
    .subtitle {
      font-weight: 300;
      font-size: 1.2rem;
      padding-bottom: 1.3rem;
      margin-bottom: 1.3rem;
      color: $textstandard;
      opacity: 0.7;
      @include bg-border(bottom);
      span {
        font-weight: 600;
      }
    }
    .loginbtn {
      width: 12.6rem;
      height: 2.75rem;
      border-radius: 9px;
      background: #008ace;
      box-shadow: 0px 0px 12px rgba(7, 102, 149, 0.68);
      color: white;
      font-weight: 600;
      font-size: 1rem;
      margin: 1rem 0;
      @include flexbox(0 0 auto);
      user-select: none;
      border: none;
      outline: none;
    }
    .login-options {
      @include flexbox();
      flex-wrap: nowrap;
      height: 3rem;
      .toggle {
        @include flexbox();
        font-size: 0.8rem;
        margin-right: 1rem;
        flex-wrap: nowrap;
      }
      .forgot-password {
        font-size: 0.8rem;
        flex-wrap: nowrap;
      }
    }
    .validations {
      @include flexbox();
      text-align: center;
      padding: 1rem 0;
      color: $syscored;
      max-width: 16rem;
    }
    .login-loader {
      margin: 1.6rem 0;
    }
    .language-selector-wrapper {
      @include flexbox();

      width: 100%;
      justify-content: flex-end;
      color: $textstandard;
    }

    .language-selector {
      width: 95px;
      border: 1px solid $lightgray;
      border-radius: 8px;
      span {
        color: $textstandard;
      }
    }
  }

  // Vendor or associate login selection panel
  .user-selection {
    @include flexbox(0 0 auto, column);

    margin: 1rem;

    .title {
      font-weight: 300;
      font-size: 2rem;
      margin: 1.3rem 0;
    }

    .selection {
      @include flexbox(0 0 auto, row);
      a {
        @include flexbox(0 0 auto, column);
      }
      //Responsive
      @include breakpoint(xs) {
        @include flexbox(0 0 auto, column);
      }
      li {
        @include flexbox(0 0 auto, column);
        margin: 1rem;
        width: 14rem;
        height: 12rem;
        border-radius: 9px;
        background: #fff;
        border: 1px solid rgba(134, 134, 134, 0.4);
        box-shadow: 0px 0px 8px rgba(183, 183, 183, 0.58);
        .statement {
          font-weight: 400;
          font-size: 1.2rem;
        }
        .type {
          font-weight: 700;
          font-size: 1.4rem;
        }
        .icon {
          margin: 1rem 0;
          &.vendor {
            color: $syscoblue;
            font-size: 4rem;
            background-size: contain;
            width: 4rem;
            height: 4rem;
          }
          &.associate {
            color: $syscoyellow;
            font-size: 4rem;
            background-size: contain;
            width: 3rem;
            height: 4rem;
          }
        }
        &:hover {
          cursor: pointer;
          border: 1px solid rgba(134, 134, 134, 0.1);
          box-shadow: 0px 0px 2px rgba(183, 183, 183, 0.68);
        }

        &:active,
        &:focus {
          box-shadow: 0 0 3px 3px rgba(0, 140, 209, 0.4);
        }
      }
    }
  }

  // Reset & forgot password page
  .reset-password {
    .subtitle-b {
      font-weight: 400;
      font-size: 1rem;
      width: 16rem;
      text-align: center;
      padding-bottom: 1.3rem;
      color: $textstandard;
      opacity: 0.7;
      span {
        font-weight: 600;
      }
    }
  }

  // Custom pre login level error page
  .login-message {
    width: 42rem;
    .logo {
      width: 7rem;
      margin: -0.5rem 0 0 -0.5rem;
      align-self: flex-start;
    }
    .errorimg {
      align-self: flex-end;
      margin-bottom: 2rem;
      margin-right: -3rem;
      width: 29rem;
      &.norole {
        align-self: center;
        width: 9rem;
        margin: 2rem;
      }
    }
    .subtitle-b {
      font-weight: 300;
      font-size: 1.2rem;
      width: 100%;
      text-align: center;
      padding-bottom: 1.3rem;
      color: $textstandard;
      opacity: 0.7;
      span {
        font-weight: 600;
      }
    }
  }
  &.qa-env,
  &.dev-env,
  &.prod-env {
    &::after {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}
