.tile-wrapper {
  @include flexbox(0 0 auto, column, flex-start);
  .tile {
    @include flexbox(0 0 auto, column);
    width: 13.5rem;
    height: 12rem;
    border-radius: 22px;
    // background: linear-gradient(#008ace 0%, #345995 100%);
    border: 3px solid #fff;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.4);
    opacity: 1;
    cursor: pointer;
    color: $textalternative;

    .icon {
      @include flexbox();
      font-size: 4.5rem;
      margin-top: 1rem;
      max-height: 6.5rem;
    }
    .divider {
      @include flexbox(0 0 auto);
      width: 100%;
      height: 10px;
      @include bg-border(bottom, invert);
    }
    .title {
      @include flexbox(0 0 auto);
      font-weight: 700;
      text-align: center;
      font-size: 1.1rem;
      line-height: 1;
      height: 3.5rem;
    }

    //App tile colors
    &.costing {
      background: linear-gradient(132deg, #009bd6 30%, #3e6ea6 100%);
    }
    &.promotions {
      background: linear-gradient(132deg, #eed36b 30%, #f3a484 100%);
      .icon {
        font-size: 5rem;
      }
    }
    &.marketing {
      background: linear-gradient(132deg, #ef9875 30%, #d9655c 100%);
    }
    &.payments {
      background: linear-gradient(132deg, #5cb95b 30%, #478d3b 100%);
    }
    &.rewards {
      background: linear-gradient(132deg, #9fcbe9 30%, #4e9bca 100%);
      .icon {
        font-size: 5rem;
      }
    }
    &.agreements {
      background: linear-gradient(132deg, #6d97b6 30%, #386685 100%);
      .icon {
        font-size: 6.2rem;
      }
    }
    &.eiprograms {
      background: linear-gradient(132deg, #89a5b1 30%, #5b7283 100%);
    }
    &.claims {
      background: linear-gradient(132deg, #accb89 30%, #85aa6d 100%);
    }
    &.itemmanager {
      background: linear-gradient(132deg, #b6dce3 30%, #95c6ce 100%);
    }
    &.documentbackuprepo {
      background: linear-gradient(132deg, #a273bf 30%, #77419a 100%);
    }
    &.opportunities {
      background: linear-gradient(132deg, #bf83b4 30%, #8d437e 100%);
    }
    &.osd {
      background: linear-gradient(132deg, #ddd4aa 30%, #c1a57c 100%);
    }
    &.autoslotting {
      background: linear-gradient(132deg, #de68c7 30%, #9f0b82 100%);
    }
    &.suppliermanagement {
      background: linear-gradient(132deg, #cfddaa 30%, #aec17c 100%);
      .icon {
        font-size: 5rem;
      }
    }
    animation-name: fadeHoverOut;
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transition: all ease-in-out 200ms;
    &:hover {
      animation-name: fadeHover;
    }
  }

  .tile-info {
    font-weight: 300;
    padding: 0.8rem 0.5rem 0 0.5rem;
    text-align: center;
    font-size: 1.1rem;
    color: $textstandard;
    opacity: 0.7;
  }

  &.small {
    .tile {
      width: 2rem;
      height: 2rem;
      border-radius: 5px;
      border-width: 2px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
      .icon {
        font-size: 1rem !important;
        margin: 0;
      }
    }
  }
  //Responsive
  @include breakpoint(xs) {
    .tile {
      width: 6.4rem;
      height: 6rem;
      border-radius: 12px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
      .icon {
        font-size: 2rem !important;
        margin-top: 0.4rem;
      }
      .divider {
        display: none;
      }
      .title {
        font-size: 0.8rem;
        flex: 1 1 auto;
        line-height: 1;
        height: 1rem;
        padding-bottom: 0.8rem;
      }
    }
    .tile-info {
      display: none;
    }
  }
}
//Styles for app-menu-drawer tiles
.menumode {
  .tile-wrapper {
    .tile {
      width: 4rem;
      height: 3.5rem;
      border-radius: 0.7rem;
      border: 2px solid #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
      .icon {
        font-size: 1.8rem;
        margin: 0;
        padding: 0;
        max-height: 3rem;
      }
      .divider {
        display: none;
      }
      .title {
        display: none;
      }
    }

    .tile-info {
      padding: 0.5rem 0.5rem 0 0.5rem;
      font-size: 0.8rem;
    }
  }
  @include breakpoint(xs) {
    .tile-wrapper {
      .tile {
        width: 5rem;
        height: 4.5rem;
        border-radius: 0.8rem;
        .icon {
          font-size: 2rem;
          max-height: 4rem;
        }
      }
      .tile-info {
        display: block;
        font-size: 1rem;
      }
    }
  }
}
