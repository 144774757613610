$icon-dashbaord: url('/styles/images/icons/dashboard-icon.svg');

$font-url-brix-san: url('/styles/images/fonts/BrixSans-Regular.ttf');
$font-brix-san: 'BrixSansRegular';

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: $font-brix-san;
  src: $font-url-brix-san;
}

.back-to-dashboard {
  background: $icon-dashbaord;
}
