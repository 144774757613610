@import '_tiles';
.app-grid {
  @include flexbox(1 1 auto, column, flex-start);
  padding: 0 2rem 0 1.8rem;
  height: 100%;

  .section-header {
    @include flexbox(0 0 auto, row, flex-start, center);
    width: 100%;
    height: 5rem;
    padding: 1rem 0 1rem 0.2rem;
    @include bg-border(bottom);
    .title {
      @include flexbox(0 0 auto, row);
      .icon {
        font-size: 2.5rem;
      }
      .label {
        font-size: 2rem;
        font-weight: 700;
        margin: 0 1rem;
      }
    }
  }
  .info-toggle {
    font-weight: 300;
    @include flexbox(0 0 auto);
  }
  .apps {
    display: -ms-grid;
    display: grid;
    -ms-grid-template-columns: repeat(auto-fill, 13.5rem);
    grid-template-columns: repeat(auto-fill, 13.5rem);
    -ms-grid-row-gap: 1.5vw;
    grid-row-gap: 1.5vw;
    -ms-grid-column-gap: 2vw;
    grid-column-gap: 2vw;
    width: 100%;
    padding: 2vw 0 2vw 2vw;
    overflow-y: auto;
    //Responsive
    @include breakpoint(xs) {
      display: -ms-grid;
      display: grid;
      -ms-grid-template-columns: repeat(auto-fill, 6.4rem);
      grid-template-columns: repeat(auto-fill, 6.4rem);
      -ms-grid-row-gap: 1.2rem;
      grid-row-gap: 1.2rem;
      -ms-grid-column-gap: 0.8rem;
      grid-column-gap: 0.8rem;
    }
    .top-scroll-fader,
    .bottom-scroll-fader {
      width: 72vw;
      height: 4vh;
      margin-top: -2vw;
      margin-left: -2vw;
      position: absolute;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAYAAAAlBadpAAAAAXNSR0IArs4c6QAAADxJREFUOBFj/P///y8GMgELUN9XMvUyUKz52wi0maLQHrgAG6LOHg0wErLYaH4mIbBAShmBRS8riXrgygHjoBxq0ruVVgAAAABJRU5ErkJggg==)
        repeat-x top center;
      background-size: 100% 100%;
      z-index: $midLevel;
      //Responsive
      @include breakpoint(xs) {
        display: none;
      }
    }
    .bottom-scroll-fader {
      bottom: 0;
      transform: rotate(180deg);
    }
    //For app-menu-drawer grid
  }
  //Responsive
  @include breakpoint(xs) {
    height: auto;
    .info-toggle {
      display: none;
    }
    .section-header {
      @include flexbox(0 0 auto, column, flex-start, flex-start);
      height: auto;
      .title {
        margin-bottom: 1rem;
        .icon {
          font-size: 1.5rem;
        }
        .label {
          font-size: 1.5rem;
          margin: 0 0.5rem;
        }
      }
    }
  }

  &.menumode {
    @include flexbox(0 0 auto);
    align-items: center;
    padding: 1rem 2rem 1.5rem 2rem;
    width: 17rem;
    .apps {
      display: -ms-grid;
      display: grid;
      -ms-grid-template-columns: repeat(auto-fill, 3.5rem);
      grid-template-columns: repeat(auto-fill, 3.5rem);
      -ms-grid-column-gap: 1.2rem;
      grid-column-gap: 1.2rem;
      -ms-grid-row-gap: 0.8rem;
      grid-row-gap: 0.8rem;
      padding: 0;
      overflow-y: visible;
    }

    @include breakpoint(xs) {
      padding: 1rem 1rem 1.5rem 2rem;
      width: 100%;
      .apps {
        display: -ms-grid;
        display: grid;
        -ms-grid-template-columns: repeat(auto-fill, 5rem);
        grid-template-columns: repeat(auto-fill, 5rem);
        -ms-grid-column-gap: 1rem;
        grid-column-gap: 1rem;
        -ms-grid-row-gap: 1rem;
        grid-row-gap: 1rem;
        padding: 0;
        overflow-y: visible;
      }
    }
  }
}
