.app-menu {
  @include flexbox(0 0 auto, column);
  display: none;
  position: absolute;
  top: 3rem;
  margin-left: -5rem;
  background: $background;
  z-index: $bringToTop;
  border-radius: 0.8rem;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.4);

  &:after {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(213, 213, 213, 0);
    border-bottom-color: #fff;
    border-width: 12px;
    margin-left: -12px;
  }
  .menu-header {
    @include flexbox(0 0 auto);
    padding: 1rem 1rem;
    width: 100%;
    color: $textstandard !important;
    .title {
      font-weight: 700;
      font-size: 1rem;
      @include flexbox(0 0 auto);
      margin-right: 1rem;
      .icon {
        margin-right: 0.5rem;
      }
    }
    .icon {
      color: $textstandard;
      font-size: 1.2rem;
      margin: 0;
    }
  }
  @include breakpoint(xs) {
    position: fixed;
    top: 5rem;
    right: 0;
    left: 0;
    margin-left: 0;
    width: 100vw;
    &:after {
      left: auto;
    }
    .menu-header {
      .title {
        font-size: 1.4rem;
      }
      .icon {
        font-size: 2rem;
      }
    }
  }
}
