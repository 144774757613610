/***********************************************************
    SYSCO SUPPLIER SUITE 
    PRIMARY SASS FILE
    Author: Michael Lu 
    (Auto Compiled to CSS during runtime)
***********************************************************/

@charset "UTF-8";

// Initial Imports and resets
@import 'bt-ux-framework/bt-ux-framework';

//Login Imports
@import 'apps/login/login';

//Dashboard app import
@import 'apps/dashboard/_dashboard';

//OSD app import
@import 'apps/osd/_osd';

//import Maintenance - reusable in any micro application
@import 'apps/maintenance/_maintenance';

//import logon message
@import 'apps/logon-message/_logon-message';
